import axiosInstance from "../axios/axios";

export const validateTokenAPI = async (token) => {
 try {
   const response = await axiosInstance.get(`/validate-token/${token}`);
   return [response, null];
 } catch (error) {
   return [null, error];
 }
};

export const submitEnrollmentForm = async (data) => {
  try {
    const response = await axiosInstance.post("/client", data);
    return [response, null];
  } catch (error) {
    return [null, error];
  } 
}

export const verifyEnrollmentForm = async (data) => {
  try {
    const response = await axiosInstance.post("/verify-client", data);
    return [response, null];
  } catch (error) {
    return [null, error];
  } 
}

export const enrollmentInfo = async () => {
 try {
   const response = await axiosInstance.get("/enrollment-info");
   return [response, null];
 } catch (error) {
   return [null, error];
 } 
}
