import { notification } from "antd";

export const openErrorNotification = (description, message) => {
  notification["error"]({
    message: message,
    description: description,
    duration: 4,
  });
};

export const openSuccessNotification = (
  description,
  message
) => {
  notification["success"]({
    message: message,
    description: description,
    duration: 4,
  });
};
