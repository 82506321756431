// import logo from './logo.svg';
import { BrowserRouter } from "react-router-dom";
import Routes from "./routes/index";
import "antd/dist/antd.css";
import './App.css';

function App() {
  return (
    <BrowserRouter>
      {/* <ErrorBoundary> */}
      <Routes />
      {/* </ErrorBoundary> */}
    </BrowserRouter>
  );
}

export default App;
