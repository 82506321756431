import { useEffect, useState, useRef } from "react";
import { useParams, Link } from "react-router-dom";
import { Form, Input, Button, DatePicker, Result, Spin, Avatar, Row, Col, Radio } from "antd";
import { PhoneOutlined } from '@ant-design/icons';
import moment from 'moment';
import Group from "../assets/images/Group160.png";
import Logo from "../assets/images/hgi_logo.png";
import SuccessImg from '../assets/images/success.png';
import {validateTokenAPI,submitEnrollmentForm,verifyEnrollmentForm,enrollmentInfo} from '../api/api'
import { openErrorNotification } from "./notification";
import platform from "platform";

function Token() {
  const { token } = useParams();
  const [form] = Form.useForm();
  const firstNameInput = useRef(null);

  let [emailConfirmed, setEmailConfirmed] = useState(false);
  let [tokenValidating, setTokenValidating] = useState(true);
  let [loading, setLoading] = useState(false);
  let [editable, setEditable] = useState(false);
  let [serverError, setServerError] = useState(null);
  let [formValues, setFormValues] = useState(null);
  let [contactInfo, setContactInfo] = useState(null);
  const [dateType, setDateType] = useState('year');
  const [openPicker, setOpenPicker] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedDate, setSelectedDate] = useState("");

  useEffect(() => {
    getContact()
    if(firstNameInput && firstNameInput.current){
      // @ts-ignore: Object is possibly 'null'.
      firstNameInput.current.focus();
    }
    if(token){
      validateToken();
    }
//     var nVer = navigator.appVersion;
// var nAgt = navigator.userAgent;
// var browserName  = navigator.appName;
// var fullVersion  = ''+parseFloat(navigator.appVersion); 
// var majorVersion = parseInt(navigator.appVersion,10);
// var nameOffset,verOffset,ix;

// // In Opera, the true version is after "Opera" or after "Version"
// if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
//  browserName = "Opera";
//  fullVersion = nAgt.substring(verOffset+6);
//  if ((verOffset=nAgt.indexOf("Version"))!=-1) 
//    fullVersion = nAgt.substring(verOffset+8);
// }
// // In MSIE, the true version is after "MSIE" in userAgent
// else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
//  browserName = "Microsoft Internet Explorer";
//  fullVersion = nAgt.substring(verOffset+5);
// }
// // In Chrome, the true version is after "Chrome" 
// else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
//  browserName = "Chrome";
//  fullVersion = nAgt.substring(verOffset+7);
// }
// // In Safari, the true version is after "Safari" or after "Version" 
// else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
//  browserName = "Safari";
//  fullVersion = nAgt.substring(verOffset+7);
//  if ((verOffset=nAgt.indexOf("Version"))!=-1) 
//    fullVersion = nAgt.substring(verOffset+8);
// }
// // In Firefox, the true version is after "Firefox" 
// else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
//  browserName = "Firefox";
//  fullVersion = nAgt.substring(verOffset+8);
// }
// // In most other browsers, "name/version" is at the end of userAgent 
// else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
//           (verOffset=nAgt.lastIndexOf('/')) ) 
// {
//  browserName = nAgt.substring(nameOffset,verOffset);
//  fullVersion = nAgt.substring(verOffset+1);
//  if (browserName.toLowerCase()==browserName.toUpperCase()) {
//   browserName = navigator.appName;
//  }
// }
// // trim the fullVersion string at semicolon/space if present
// if ((ix=fullVersion.indexOf(";"))!=-1)
//    fullVersion=fullVersion.substring(0,ix);
// if ((ix=fullVersion.indexOf(" "))!=-1)
//    fullVersion=fullVersion.substring(0,ix);

// majorVersion = parseInt(''+fullVersion,10);
// if (isNaN(majorVersion)) {
//  fullVersion  = ''+parseFloat(navigator.appVersion); 
//  majorVersion = parseInt(navigator.appVersion,10);
// }

// var OSName = "Unknown OS";
//   if (navigator.userAgent.indexOf("Win") != -1) OSName = "Windows";
//   if (navigator.userAgent.indexOf("Mac") != -1) OSName = "Macintosh";
//   if (navigator.userAgent.indexOf("Linux") != -1) OSName = "Linux";
//   if (navigator.userAgent.indexOf("Android") != -1) OSName = "Android";
//   if (navigator.userAgent.indexOf("like Mac") != -1) OSName = "iOS";
//   console.log('Your OS: ' + OSName);

// console.log(''
//  +'Browser name  = '+browserName+'<br>'
//  +'Full version  = '+fullVersion+'<br>'
//  +'Major version = '+majorVersion+'<br>'
//  +'navigator.appName = '+navigator.appName+'<br>'
//  +'navigator.userAgent = '+navigator.userAgent+'<br>'+
//  OSName
// )
// console.log(
//   platform,
//   navigator,
//   platform.name, // 'IE'
//   platform.version, // '10.0'
//   platform.layout, // 'Trident'
//   platform.os, // 'Windows Server 2008 R2 / 7 x64'
//   platform.description,
//   navigator.clipboard // 'IE 10.0 x86 (platform preview; running in IE 7 mode) on Windows Server 2008 R2 / 7 x64
// )

// or on an iPad
// platform.name; // 'Safari'
// platform.version; // '5.1'
// platform.product; // 'iPad'
// platform.manufacturer; // 'Apple'
// platform.layout; // 'WebKit'
// platform.os; // 'iOS 5.0'
// platform.description; // 'Safari 5.1 on Apple iPad (iOS 5.0)'

// or parsing a given UA string
// var info = platform.parse('Mozilla/5.0 (Macintosh; Intel Mac OS X 10.7.2; en; rv:2.0) Gecko/20100101 Firefox/4.0 Opera 11.52');
// console.log(info,
//   info.name, // 'Opera'
//   info.version, // '11.52'
//   info.layout, // 'Presto'
//   info.os, // 'Mac OS X 10.7.2'
//   info.description,)
  }, [token]);

  const onYearChange = (e) => {
    setSelectedYear('')
    setSelectedMonth('')
    setTimeout(()=>{
      setSelectedYear(e)
      setSelectedMonth(e)
    },1000)
  }

  const onMonthChange = (e) => {
    setSelectedMonth('')
    setTimeout(()=>{
      setSelectedMonth(e)
    },1000)
  }

  const onDateChange = (e) => {
    setSelectedDate(e)
  }

  async function getContact(){
		const[response,err] = await enrollmentInfo();
		if(response){
			setContactInfo(response.data.data.contacts)
		}
		if(err){
			console.log(err)
		}
	}

  async function validateToken() {
    const [response, err] = await validateTokenAPI(token);
    if (response) {
      setFormValues(response.data)
      if(response.data.is_old===false){
        setEditable(true)
      }
      setTokenValidating(false)
      setEmailConfirmed(true)
    }
    if (err) {
      setTokenValidating(false)
      setEmailConfirmed(true)
      if(err.response){
        if(err.response.data){
          if(err.response.data.error.message==="Token has expired." ){
            setServerError("Sorry! The link you are using has expired!!")
          } else if (err.response.data.error.message==="Token was already used."){
            setServerError("You have already updated your information. Thank You!!")
          }else {
            setServerError("The link you used seems to be wrong. Please try again.")
          }
        }
      } else {
        setServerError("Oops! Something went wrong")
      }
    }
  }

  const submitForm = async (values) => {
    setLoading(true)
    let data = {
      "name": values.name,
      "dob": moment(selectedDate).format('YYYY-MM-DD'),
      "email": values.email,
      "address": values.address,
      "gender": values.gender,
      "token": token,
    }
    const [response, err] = await submitEnrollmentForm(data)
    if(response){
      setServerError("Form Submitted Successfully")
    }
    if(err){
      openErrorNotification('Oops! Something went wrong', 'Error')
    }
    setLoading(false)
  }



  const verifyCustomer = async () => {
    setLoading(true)
    let data = {
      "token": token,
    }
    const [response, err] = await verifyEnrollmentForm(data)
    if(response){
      setServerError('Form Verified Successfully')
    }
    if(err){
      openErrorNotification('Oops! Something went wrong', 'Error')
    }
    setLoading(false)
  }

  const setStatus = (error) => {
    if(error==="Sorry! The link you are using has expired!!"){
      return "403"
    }
    if(error==="The link you used seems to be wrong. Please try again."){
      return "404"
    }
    if(error==="Form Submitted Successfully"){
      return "success"
    }
    if(error==="Form Verified Successfully"){
      return "success"
    }
    if(error==="Oops! Something went wrong") {
      return "500"
    }
    if(error==="You have already updated your information. Thank You!!"){
      return ""
    }
  }

  const handleDateClick = () => {
    if(!selectedDate){
      setDateType("year")
    }
    setOpenPicker(true)
  }

  function PickerWithType({ type, onChange }) {
    return (
      <DatePicker 
        onClick={()=>handleDateClick()} 
        open={openPicker} 
        style={{width: "100%"}} 
        value={selectedDate} 
        picker={type}
        onChange={onChange}
        showToday={false}
        disabledDate = {(current)=>{
          return current > moment('2004').endOf('days');
        }}
      />
        );
  }

  const handleChange = (value) => {
    setOpenPicker(true)
    if(dateType==="year"){
      setDateType("month")
    }
    if(dateType==="month"){
      setDateType("date")
    }
    if(dateType==="date"){
      setOpenPicker(false)
    }
    setSelectedDate(value)
  }

  const onCalendarChange = (e) => {
    console.log(moment(e).format('YYYY-MM-DD'))
    setSelectedDate(e)
  }



  return (
    <div className={(emailConfirmed || !token) ? "" : "error__page__wrapper"}>
      <div className={(emailConfirmed || !token) ? "" : "page__inside"}>
        <div>
            <>
              {(tokenValidating && token) && <Spin size="large" />}
              {emailConfirmed || !token ? (
                <>
                  <div className="navtop border-bottom border-1">
                    <div className="container">
                      <div className="d-flex pt-2 pb-3">
                        <img src={Logo} alt="hgi_logo" className="img-fluid" />
                      </div>
                    </div>
                  </div>

                  <div className="contactus ">
                    <div className="container">
                      <div className="row">
                        <div className="col-lg-7">
                          <img
                            src={Group}
                            alt="group"
                            className="img-fluid imgstyle"
                          />
                        </div>
                        <div className="col-lg-5 ">
                          <div className="formstyle">
                          {(!serverError&&token)?
                          <>
                            <h3 className="pb-4 h3style">
                              {editable?"Update":"Verify"} Your Information
                            </h3>
                            <Form layout="vertical" form={form} onFinish={submitForm}>
                              <Form.Item
                                label="Name"
                                name="name"
                                ref={firstNameInput}
                                rules={[
                                  {
                                    required: editable,
                                    message: "Please input your name!",
                                  },
                                ]}
                              >
                                {!editable?<strong>{formValues?.name}</strong>:
                                  <Input autoFocus />
                                }
                              </Form.Item>
                              <Form.Item
                                label="Date of Birth"
                                name="dob"
                              >
                                {!editable?<strong>{formValues?.dob}</strong>:
                                  <PickerWithType type={dateType} onChange={value => handleChange(value)} />
                                }
                              </Form.Item>
                              <Form.Item
                                name="gender"
                                label="Gender"
                              >
                                {!editable?<strong>{formValues?.gender}</strong>:
                                  <Radio.Group disabled={!editable}>
                                    <Radio value="Male">Male</Radio>
                                    <Radio value="Female">Female</Radio>
                                    <Radio value="Other">Other</Radio>
                                  </Radio.Group>
                                }
                              </Form.Item>
                              <Form.Item
                                label="Email"
                                name="email"
                                rules={[
                                  {
                                    type: 'email',
                                    message: 'Please input valid E-mail!',
                                  }
                                ]}
                              >
                                { !editable ? <strong>{formValues?.email}</strong> :
                                  <Input />
                                 }
                              </Form.Item>
                              <Form.Item
                                label="Address"
                                name="address"
                              >
                                {!editable? <strong>{formValues?.address}</strong> :
                                  <Input />
                                }
                              </Form.Item>
                              {
                                !editable?
                                  <>
                                  <Form.Item>
                                    <div className="d-grid ">
                                      <Button loading={loading} type="primary" onClick={verifyCustomer}>
                                        Verify
                                      </Button>
                                    </div>
                                  </Form.Item>
                                  <Form.Item>
                                    <div className="d-grid ">
                                      <Button type="primary" onClick={()=>setEditable(true)}>
                                        Update
                                      </Button>
                                    </div>
                                  </Form.Item>
                                  </>
                                :''
                              }
                              {editable?
                                <Form.Item>
                                  <div className="d-grid ">
                                    <Button type="primary" loading={loading} htmlType="submit">
                                      Submit
                                    </Button>
                                  </div>
                                </Form.Item>
                              :''}
                            </Form>
                            </>
                            :
                            token? serverError==="You have already updated your information. Thank You!!"?
                              <Result
                                icon={<Avatar src={SuccessImg} size={{ xs: 200, sm: 200, md: 200, lg: 200, xl: 200, xxl: 250 }}/>}
                                status={setStatus(serverError)}
                                title={serverError}
                              />
                            :
                            <Result
                              status={setStatus(serverError)}
                              title={serverError}
                            />
                            : 
                            <>
                              <h1>Welcome to Himalayan General Insurance,</h1>
                              <p>
                              This document provides all details of the "Ncell Data Sanga Beema" hospitalization benefits you have subscribed to, and how you can avail them if needed. Stay Safe!
                              </p> 
                            </>
                            }
                            <div className="terms text-center pt-5 mb-5 border-top">
                              <Link
                                to="/terms-and-conditions"
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-decoration-none"
                                style={{ color: "#3A4065" }}
                              >
                                {" "}
                                <span className="me-lg-5 me-md-5 me-sm-5 me-2" style={{fontSize: '15px', color: '#7e7ee1'}}><strong>Terms & Conditions</strong></span>
                              </Link>
                              <Link
                                to="/faq"
                                target="_blank" 
                                rel="noopener noreferrer"
                                className="text-decoration-none"
                                style={{ color: "#3A4065" }}
                              >
                                {" "}
                                <span style={{fontSize: '15px', color: '#7e7ee1'}}><strong>FAQ</strong></span>
                              </Link>
                            </div>
                            <div className="contactno pb-4 pt-4 border-top border-1" style={{position: "relative", bottom: 10}}>
                              <p><strong>Contact No.</strong></p>
                              { contactInfo? contactInfo.map((contact, index) => 
                                <div key={index} className="d-flex pb-2 contactno_style">
                                  <span className="me-auto text-muted fw-lighter">
                                    <PhoneOutlined className="mb-1 me-2" />
                                    {contact.name || 'N/A'}
                                  </span>
                                  <span className="text-muted fw-lighter">
                                    {contact.phone_number || '98XXXXXXXX'}
                                  </span>
                                </div>
                              ) : ''}
                            </div>

                            {/* </form> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <Affix style={{ position: 'absolute', bottom: 0, width:"100%" }}> */}
                    {/* <footer className=" d-flex align-items-center justify-content-center pt-3 footerstyle"> */}
                      <Row className="pt-3 footerstyle" style={{position: 'relative', bottom: 0}}>
                        <Col span={24}>
                          <div className="container ">
                            <p className="text-center">
                              {token?
                             ` "Information collected through this form will be
                              retained by HGI to enable provision of insurance. This
                              will not be shared with third parties."`
                              : ''}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    {/* </footer> */}
                  {/* </Affix> */}
                </>
              ) : (
                ""
              )}
            </>
        </div>
        {/* {(tokenValidating && token) ? (
          <img src={WaitingEmail} alt="" />
        ) : (
          //   <img
          //   src={
          //     errorConfirming
          //       ? EmailConfirming
          //       : emailConfirmed
          //       ? EmailConfirmed
          //       : PageNotFound
          //   }
          //   alt=""
          // />
          ""
        )} */}
      </div>
    </div>
  );
}

export default Token;
