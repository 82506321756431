import React from "react";
import { Route, Routes } from "react-router-dom";
import Token from '../components/Token';
import FAQ from '../components/FAQ';
import Terms from '../components/TNC';

function MainRoute() {
  return (
    <Routes>
      <Route path="/" element={<Token/>}/>
      <Route path="/:token" element={<Token/>} exact="true"/>
      <Route path="/faq" element={<FAQ/>} />
      <Route path="/terms-and-conditions" element={<Terms />} />
    </Routes>
  );
}

export default MainRoute;